import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class Gut3 extends Component {

render() {
return (


<div class="container">

<br></br>
<hr></hr>

{ this.props.account
? <img
className='ml-1 rounded-5'
width='24'
height='24'
src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
: <span></span>
}

{ this.props.account
? <img
className='ml-1 rounded-5 mr-2'
width='24'
height='24'
src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
: <span></span>
}

<h5 className="text-primary">Shareholder Account: <span id="account">{this.props.account}</span></h5>



<section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="shareholdersteps">
<div class="w-100">
<h2 class="mb-5">Becoming a Shareholder</h2>
<ul class="fa-ul mb-0">
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
1<sup>st</sup>Step in Joining
Regency Central - Shareholders</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
2<sup>nd</sup>Step in Joining
Regency shareholders can manage their holdings</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
3<sup>rd</sup>Step in Joining
Regency plan types including options, restricted units, performance units, and employee purchase plans.</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
4<sup>th</sup>Step in Joining
New and existing shareholders can interact and invest directly into a Regency project, without a broker, provider they qualify under SEC Rules and Regulations.</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
5<sup>th</sup>Step in Joining
Based on new SEC Rules investors can not only buy and sell stocks online but also vote their proxy statements. </li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
6<sup>th</sup>Step in Joining
It is important for shareholders to participate and vote based on fully understanding the relevent documentation.The entire documentation is deliveed electronically to shareholders. The shareholders access the documents with a control number or signing identity and execute a voting transaction as a vote for or against the resolution.</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
7<sup>th</sup>Step in Joining
Voting power is also earned by operating as a subcontractor to the WorldLaw protocols. Participants are awarded voting power based on they ownership interst in the WorldLaw system. </li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
3<sup>rd</sup>Step in Joining
This is accomplished by locking Ether or some approved ERC20 tokens into the Regency protocol, such as or bidding WLX pawns. </li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
4<sup>th</sup>Step in Joining
With the Regency protocols there is no pre-allocation of voting rights. Regency utilizes a governance structure which is accessible through the Regency.eth user interface.</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
5<sup>th</sup>Step in Joining
View proxy materials, request paper copies, and vote your online proxy </li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
6<sup>th</sup>Step in Joining
Participants are granted governance votes based on the amount of equity they own in the underlying company that owns the protocols, Golbal REO XVIII LLC based proportionally to how much everyone else locks, registers, or bids.</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
7<sup>th</sup>Step in Joining
Regency-Text-Field</li>
</ul>
</div>
</section>

<br></br>
<hr></hr>

<section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="secregs">
<div class="w-100">
<h2 class="mb-5">SEC Regulations in Selling Securities</h2>

<div class="resume-item d-flex flex-column flex-md-row justify-content-between">
<div class="resume-content">
<h3 class="mb-0">Regulation D Rule 506(c) and Reg CrowdFunding (CF)</h3>
<br></br>
<div class="subheading mb-3">General Solicitation for Rule 506(c)</div>
<p>The Jumpstart Our Business Startups Act (the “JOBS Act”),1 enacted on April 5, 2012, established a regulatory structure for startups and small businesses to raise capital through securities offerings through crowdfunding.2 On October 30, 2015, the Commission adopted the final rules for Regulation Crowdfunding to implement Title III of the JOBS Act (“Title III”). 3 In the Adopting Release, the Commission stated that “staff will undertake to study and submit a report to the Commission no later than three years following the effective date of Regulation Crowdfunding on the impact of the regulation on capital formation and investor protection.”4 Consistent with the Adopting Release, staff has undertaken a study of the available information on the capital formation and investor protection impacts of Regulation Crowdfunding.</p>
<p>The JOBS Act crowdfunding provisions were intended to help startups and small businesses raise capital in a less costly manner by making relatively low dollar offerings of securities to a “crowd” of interested investors.5 On October 30, 2015, the Commission adopted the final rules for Regulation Crowdfunding, which went into effect on May 16, 2016, with the exception of the provisions governing funding portal registration, which became effective on January 29, 2016.</p>

<h3 class="mb-0">Concurrent Reg CF and Rule 506(c) offering</h3>

<p>There is no specific safe harbor for a concurrent Reg CF and Rule 506(c) offering, however Section 4A(g) provides that nothing in Reg CF should be construed as preventing an issuer from raising capital through other methods. Therefore under the 2007 Integration Principles (repeated in Reg CF Adopting Release) would permit concurrent offerings, but any general solicitation including the “terms” of the Reg CF offering (amount, price, type of security or closing date) would be limited to the Rule 204 “tombstone” restrictions on content (unless issuer were able to show clearly that Reg CF investors weren’t attracted to the offering by the broader advertising of the 506(c) offering.</p>
<p>The SEC offers some guidance by interpreting the term “offer” broadly to include any statements that “have the effect of conditioning the public mind or arousing public interest” presumably to invest in a company. The basic premise is advertisements that don’t include the offering’s terms need not comply with Rule 204(b)</p>
<p>Generally, an entity that owns Interests is treated as only 1 partner in determining whether there are 100 or more partners. However, all of the owners of an entity that is a pass-through vehicle for tax purposes and that invests in a partnership are counted as partners if substantially all of such entity’s value is attributable to its interest in the partnership, and a principal purpose of the tiered structure is to avoid the 100 partner limitation. The Company may not comply with this safe-harbor if the Company admits more than 100 Members. Even if the Company exceeds 100 Members and thus does not qualify for this safe-harbor, the Operating Agreement contains provisions restricting transfers and withdrawals of Interests that may cause Interests to be treated as not being tradable on the substantial equivalent of a secondary market.</p>
</div>


<div class="resume-date text-md-right">
<span class="text-primary">506(c) vs. CF</span>
</div>
</div>

<br></br>
<hr></hr>

<div class="resume-item d-flex flex-column flex-md-row justify-content-between">
<div class="resume-content">


<h3 class="mb-0">Fund Raising by Selling Securities</h3>
<br></br>
<div class="subheading mb-3">Yes, it is a equity interest.</div>
<p><strong>Yes, you will have rights to profits.</strong></p>
<p>The Regency.eth protocols is owned by Global REO XVIII, LLC (GREO) doing business as WorldLaw. GREO was incorporated in the State of Nevada on September 1, 2008. </p>
<p>Crowdfunding operates under Title IV of the JOBS Act, allowing private companies to advertise the sale of their stock to both accredited and non-accredited investors under Regulation A, and under Title II of the JOBS Act, which permits offerings to accredited investors to be advertised under Rule 506(c) of Regulation D. </p>
<p>Equity is comprised of common and preferred equity (including partnership/membership units and interests).</p>
<p>The jurisdictions in which the issuer intends to offer the Securities: Alabama, Alaska, Arizona, Arkansas, California, Colorado, Connecticut, Delaware, District Of Columbia, Florida, Georgia, Hawaii, Idaho, Illinois, Indiana, Iowa, Kansas, Kentucky, Louisiana, Maine, Maryland, Massachusetts, Michigan, Minnesota, Mississippi, Missouri, Montana, Nebraska, Nevada, New Hampshire, New Jersey, New Mexico, New York, North Carolina, North Dakota, Ohio, Oklahoma, Oregon, Pennsylvania, Rhode Island, South Carolina, South Dakota, Tennessee, Texas, Utah, Vermont, Virginia, Washington, West Virginia, Wisconsin, and Wyoming </p>

<h3 class="mb-0">Crowdfunding Involves Risk</h3>

<p>Potential investors should not invest any funds in an offering unless they are prepared to lose the entire investment. In making an investment decision, investors must rely on their own examination of the issuer and the terms of the offering, including the merits and risks involved. Securities unde Regulation CF and 506(c) have not been recommended or approved by any federal or state securities commission or regulatory authority. Furthermore, no governement authorities have assessed the accuracy or adequacy of aby offering relating to this website. The U.S. Securities and Exchange Commission does not endorse or support the merits of any securities offered or the terms of an CF and 506(c) offering, nor does it endorse or support the accuracy or completeness of any offering document or literature. These securities are offered under an exemption from registration; however, the U.S. Securities and Exchange Commission has not made an independent determination that these securities are exempt from registration.</p>

<h3 class="mb-0">Required Third Party Escrow</h3>
<p>A fidiciary trust company provides technology services for processing investment transactions and electronic execution of subscription agreements and all escrow services related to any offerings on this platform.</p>
</div>
<div class="resume-date text-md-right">
<span class="text-primary">Important Information for Investors</span>
</div>
</div>
</div>
</section>

<section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="proxy">
<div class="resume-item d-flex flex-column flex-md-row justify-content-between">
<div class="resume-content">
<h3 class="mb-0">Proxy Voting</h3>
<div class="subheading mb-3">An easier way to create a consensus</div>
</div>
</div>

<div class="w-100">
<h2 class="mb-1">Proxy Voting</h2>
<ul class="fa-ul mb-0">
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
1<sup>st</sup>Membership Reward
How Does Proxy Voting Work?</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
2<sup>nd</sup>Membership Reward
The Regency portal governs and owns the WorldLaw system of porotocols. Voting power is proportional to the shares owned. Voting power is also earned by operating as a subcontractor to the WorldLaw protocols. Participants are awarded voting power based on they ownership interst in the WorldLaw systemn. This is accomplished by locking Ether or some approved ERC20 tokens into the Regency protocol, such as or bidding WLX pawns. Participants are granted governance votes based on the amount of equity they own in the underlying company that owns the protocols, Golbal REO XVIII LLC based proportionally to how much everyone else locks, registers, or bids. With the Regency protocols there is no pre-allocation of voting rights. Regency utilizes a governance structure which is accessible through the Regency.eth user interface.</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
3<sup>rd</sup>Membership Reward
It is very important for shareholders to participate in the voting and make their decisions based on a full understanding of the information and legal documentation presented to them. In the internet age, investors can not only buy and sell stocks online but also vote their proxy statements. The entire documentation delivery process can be electronically automated. Official documentation is delivered to shareholders in electronic form, and then they log onto a system with a control number or personal identification number and vote for or against the resolutions presented.</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
4<sup>th</sup>Membership Reward
Voting power is also earned by operating as a subcontractor to the WorldLaw protocols. Participants are awarded voting power based on they ownership interst in the WorldLaw system. </li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
5<sup>th</sup>Membership Reward
Regency-Text-Field</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
6<sup>th</sup>Membership Reward
Regency-Text-Field</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
7<sup>th</sup>Membership Reward
Regency-Text-Field</li>
</ul>
</div>

</section>

</div>

);
}
}

export default Gut3;
