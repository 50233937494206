import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class Gut2 extends Component {

render() {
return (

<div class="container">

{ this.props.account
? <img
className='ml-1 rounded-5'
width='24'
height='24'
src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
: <span></span>
}

{ this.props.account
? <img
className='ml-1 rounded-5 mr-2'
width='24'
height='24'
src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
: <span></span>
}

<h5 className="text-primary">Shareholder Account: <span id="account">{this.props.account}</span></h5>



<section class="resume-section p-3 p-lg-5 d-flex justify-content-center" id="protocols">
<div class="w-100">
<h2 class="mb-5">What are the Regency Protocols?</h2>

<div class="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
<div class="resume-content">
<h3 class="mb-0">Shareholder Management</h3>
<div class="subheading mb-3">regency text</div>
<p>regency explanatory text here</p>
</div>
<div class="resume-date text-md-right">
<span class="text-primary">Regency text goes here…..</span>
</div>
</div>

<div class="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
<div class="resume-content">
<h3 class="mb-0">Proxy Voting</h3>
<div class="subheading mb-3">regency text</div>
<p>Proxies of funds or accounts that specify the use of proxy guidelines other than the Proxy Guidelines will be voted in accordance with these other guidelines. Regency management may choose not to vote proxies in certain situations or for certain accounts either where it deems the cost of doing so to be prohibitive or where the exercise of voting rights could restrict the ability of an account’s portfolio manager to freely trade a security in question. Due to some local, State, and Federal restrictions, Regency management must balance the benefits to its clients of voting proxies against the potentially serious portfolio management consequences of a reduced flexibility to manage Regency shares at the most advantageous times. For shareholders subject to share blocking periods, the disadvantage of being unable to sell securities regardless of changing conditions may outweigh the advantages of voting at the shareholder meeting for routine items.</p>
<div><small>https://www.sec.gov/Archives/edgar/data/1301483/000119312519161379/d747829dex99proxypol.htm</small></div>

</div>
<div class="resume-date text-md-right">
<br></br>
<span class="text-primary">Regency text goes here…..</span>
</div>
</div>

<div class="resume-item d-flex flex-column flex-md-row justify-content-between">
<div class="resume-content">
<h3 class="mb-0">Subcontractor Operations</h3>
<div class="subheading mb-3">regency text</div>
<p>regency explanatory text here</p>
</div>
<div class="resume-date text-md-right">
<span class="text-primary">Regency text goes here…..</span>
</div>
</div>

</div>

</section>

<section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="govplatform">
<div class="w-100">
<h2 class="mb-5">Regency Central</h2>
<div class="subheading mb-0">A Portal to manage corporate assets</div>
<p class="lead mb-3">Corporate governance is an perpetual challenge for management teams. Governance services are built in to the Regency protocols that allow owners access to services that manage the organization and ovesees the WorldLaw protocols.</p>

<li>Analysis of management and shareholder proposals</li>
<li>Analysis of multiple plan scenarios</li>
<li>Assessment of the impact that a negative vote recommendation may have </li>
<li>By providing members and shareholders regular updates regarding regulatory changes and system developments, and disclosures members and shareholders stay up-to-date. The Regency protocols provide information to glean insights into the best practices company and platform governance.</li>
<li>Corporate Governance Consulting & Proactive Solutions</li>
<li>Deployment of communication campaigns</li>
<li>Deployment of shareholder engagement strategies</li>
<li>Evaluation of proxy participation payment guidelines, pay-on-say-model and quantitative performance model</li>
<li>Global helps owners develop strategies to manage issues that need to be addressed and responded to by the owners.</li>
<li>Regular updates of internal notices and corporate governance best practices.</li>
<li>Review of applicable investor policies</li>
<li>Targeted Investor Communications Campaigns</li>
<li>Vote Projections</li>

<hr></hr>

<p class="lead text-primary mt-3 mb-3">Shareholders</p>

<div class="resume-item d-flex flex-column flex-md-row justify-content-between">
<div class="resume-content">
<h3 class="mb-0">Regency Central for Shareholders</h3>
<div class="subheading mb-3">Where shareholders manage their holdings</div>
<p>Regency plan types including options, restricted units, performance units, and employee purchase plans. New and existing shareholders can interact and invest directly into a Regency project, without a broker, provider they qualify under SEC Rules and Regulations. The Regency protocols also supports proxy voting. </p>
<p class="lead mt-3 mb-3"><small>Analysis of management and shareholder proposals</small></p></div>
<div class="resume-date text-md-right">
<span class="text-primary">Click here to view materials, explore investment options.</span>
</div>
</div>

<li>Central Shareholders Text</li>
<li>Central Shareholders Text</li>
<li>Central Shareholders Text</li>
<li>Central Shareholders Text</li>
<li>Central Shareholders Text</li>

<hr></hr>

<p class="lead text-primary  mt-3 mb-3">Subcontractors</p>

<div class="resume-item d-flex flex-column flex-md-row justify-content-between">
<div class="resume-content">
<h3 class="mb-0">Regency Central for Subcontractors</h3>
<div class="subheading mb-3">regency  text</div>
<div>The Regency protocols report on the overall framework and participation of major proxy votes on each shareholder. The services broadcasts the proxy vote filings by identifying potential recommendations pursuant to a proxy advisory firm or institutional investor voting analysis. This governance approach ensures that governance practices are properly aligned and provide clear communication with shareholders to proactively manage objectives.</div>

</div>
<div class="resume-date text-md-right">
<span class="text-primary">Service Providers</span>
</div>
</div>

<br></br>
<li>Central Subcontractor Text</li>
<li>Central Subcontractor Text</li>
<li>Central Subcontractor Text</li>
<li>Central Subcontractor Text</li>
<li>Central Subcontractor Text</li>
<li>Central Subcontractor Text</li>
<li>Central Subcontractor Text</li>
<li>Central Subcontractor Text</li>


<p class="lead mt-3 mb-3"><small>paragraph of information here. paragraph of information here. paragraph of information here</small></p>
</div>
</section>
</div>

);
}
}

export default Gut2;
