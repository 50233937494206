import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';



class Navbar extends Component {

  render() {
    return (
      <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap shadow mb-5">

<div class="container">

            { this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='50'
                height='50'
                src={makeBlockie(this.props.account, 50).toString()} alt="AcctIconImage"/>
              : <span></span>
            }

            { this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='50'
                height='50'
                src={`data:image/png;base64,${new Identicon(this.props.account, 50).toString()}`} alt="AcctIconImage"/>
              : <span></span>
            }



<small className="text-white">Account: <span id="account">{this.props.account}</span></small>

            <a class="navbar-brand js-scroll-trigger" href="#page-top">
          <span class="d-block d-lg-none">Regency.eth</span>
            </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
           <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#about">Regency Governance</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#executechaintx">Download from Blockchain</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#protocols">Regency Protocols</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#technologies">Integrated Technology</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#blockchains">Public Blockchain</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#govplatform">Governance Platform</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#proxy">Proxy Voting</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#shareholdersteps">Becoming a Shareholder</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#secregs">SEC Rules for Investing in Securities</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#terms">Terms of Use and Privacy Policy</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#disclaimers">Legal Disclaimer</a>
          </li>
          </ul>
      </div>
      </div>
  </nav>
);
}
}

export default Navbar;
