import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class Gut5 extends Component {

render() {
return (

<div class="container">

{ this.props.account
? <img
className='ml-1 rounded-5'
width='24'
height='24'
src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
: <span></span>
}

{ this.props.account
? <img
className='ml-1 rounded-5 mr-2'
width='24'
height='24'
src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
: <span></span>
}

<small className="text-black">Shareholder Account: <span id="account">{this.props.account}</span></small>


<section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="terms">
<div class="w-100">
<h2 class="mb-2">Terms of Use and Privacy Policy</h2>
<div class="resume-item d-flex flex-column flex-md-row justify-content-between">
<div class="resume-content">
<div class="subheading mb-3">Access Policy</div>

<h3 class="mb-0">Requirements to Use this Site and Services</h3>
<p>By accessing the Site (by invitation or otherwise) or by communicating with Issuers, Investors and other Users, you agree to (i) ensure at all times that information about you or provided by you (i.e., your Content) is true, accurate, current, complete and not misleading; and (ii) promptly comply with Service Provider's request for specific action(s) or additional Content. Users are responsible for all activities that occur under their account. Service Provider may revoke access to the Site and Services (including the Portal) at any time it determines that (a) the User fails to fully comply with the foregoing obligations or with any provision of these Terms of Services, or (b) the Users action or inaction on the Site or in connection with the Services is inconsistent with Service Provider's ojectives of ensuring transparency and compliance in good-faith on the Site, including the maintenance of high standards of commercial conduct and integrity in relations to Offerings.</p>
<p>That you have the right, authority, and capacity to agree to the Terms on your own behalf and on behalf on any entity for whom you are acting for and to abide by all of the terms contained herein, and that if any aspect of your participation in Service Provider violates provisions of the law to which you or an entity you are acting for are subject, you (and, if applicable, such entity) will immediately cease using the Services and close your account;</p>
<p>That you are at least 18 years old;</p>
<p>That you shall not use a false name or email address owned or controlled by another person with the intent to impersonate that person or for any other reason;</p>
<p>That you shall not use a name that is subject to any rights of a person other than yourself without appropriate authorization;</p>
<p>That you shall not make multiple accounts of the same type using the Services;</p>
<p>That you shall be solely responsible for maintaining the confidentiality of your password;</p>
<p>That you will update your registration information with the Company, as needed, so that it remains true, correct and complete; and</p>
<p>That you will conduct yourself in a professional manner in all your interactions with Service Provider and with every User of the Site or Services.</p>

<h3 class="mb-0">Requirements Related to Investors on Service Provider Portal</h3>

<p>That you are not relying on Service Provider to, and that you understand that we do not, endorse, support, represent or guarantee the completeness; truthfulness, accuracy or reliability of any Content or communications posted via the Services;</p>
<p>That you understand that by using the Services, you may be exposed to Content that may be offensive, harmful, inaccurate or otherwise inappropriate, and that you have no claim against Service Provider for any such material;</p>
<p>That you understand that the Service may include advertisements or other similar items, which may be related to Content, queries made through the Services, or other information, and you have no claim against Service Provider for the placement of advertising or similar content on the Services or in connection with the display of any Content or other information from the Services;</p>
<p>That you will use your own judgment, conduct your own due diligence and seek any legal, financial, tax, accounting and other professional advice that may be appropriate before making any decision to invest;</p>
<p>That you can tolerate the risk of losing any amount you invest through the Portal; and </p>
<p>That you have reviewed and understand the risks and terms of any investment you commit to make through the Site.</p>
</div>



<div class="resume-date text-md-right">
<hr></hr>
<span class="text-primary">Site Use</span>
</div>
</div>
<br></br>
<div class="resume-item d-flex flex-column flex-md-row justify-content-between">
<div class="resume-content">
<h3 class="mb-0">Privacy Policy</h3>
<br></br>
<div class="subheading mb-3">Collection of Information </div>

<h3 class="mb-0">Collection of Non-Personally Identifiable Data</h3>
<br></br>

<p>Service Provider collects non-personally identifiable information from you regarding your usage of our Site (“Non-Personally Identifiable Information”). Non-Personally Identifiable Information may include, without limitation, Site pages viewed, websites visited before visiting the Site, frequency of visits, clickstream data, browser type, operating system, organization name, internet connection speed, presentations and videos viewed, time spent viewing pages of our Site or using certain features of our Site, demographic data such as server locations, clickstream data, location services, server location, cookies existing on your computer, search criteria used and results, date and time of access or visits to our Site, frequency of visits to our Site, connection speed, and other information which does not specifically identify you. We collect this information using various technologies (some of which are described below), including, but not limited to, cookies, web beacons, web session variables, server log files, clear gifs, browser plug-ins or add-ons, or Javascript. “Cookies” are pieces of information that may be placed on your computer by a Site for the purpose of facilitating and enhancing your communication and interaction with that Site. A “web beacon” is typically a transparent graphic image (usually 1 pixel x 1 pixel) that is placed on a website or in an email which allows a website to record the simple actions of the User opening the page that contains the beacon. “Server logs” can be either a single log file or several log files automatically created and maintained by a server of activity performed by the server, which can include information about any transaction you conduct with the server. </p>

<h3 class="mb-0">Collection of Personal User Data</h3>
<br></br>

<p>Service Provider may also collect personal information from you (“Personally Identifiable Information”) when a user creates a non-custodial account with a username and a password, sends an email through this Site, purchases a service, or makes an investment through this Site. Personally Identifiable Information refers to non-public information that personally relates to or identifies you such as your name, password, age and date of birth, email address, postal mailing address, home/mobile telephone number and/or taxpayer identification number, internet protocol address, investment objectives, investment experience, assets and income, and other similar information, or other information you provide or upload to the Site such as photographs, answers to questionnaires or surveys, or responses captured in forms available on this Site.</p> 
    
<p>Requests may be made to furnish information, including, but not limited to personal or company information, demographic information, financial information about issuers, valuations, share prices, fundraising goals, financial information about individuals such as net worth and other information requested from time to time. The collection and storage of this information may include Non-Personally Identifiable Information or Personally Identifiable Information, locally on your computer or device using mechanisms such as browser web storage or application data caches; however, not all Non-Personally Identifiable Information and Personally Identifiable Information is stored by this Site.</p>

<h3 class="mb-0">USe of Analytics</h3>
<br></br>
<p>Analytic programs and service providers may be used which report on your interactions with this Site. This may include but is not limited to the time, date and frequency of each Site visit and what resource was used as a referral to the Site, and what pages are viewed on this Site.</p>
<p>This Privacy Policy does not cover the collection of information by other methods used by formal Third Party Services or other third parties, which may be informal business partners. Third Party Services or third parties collect information by means their own information policies.</p>
<p>Service Provider will use information provided in this site consistent with this Privacy Policy. Service Provider will use information provided to better serve you and enhance Site experience and further the Site purpoase and objectives. This may include having to modify or copy such Non-Personally Identifiable Information or Personally Identifiable Information, or other information such as images or textual content provided to achieve the Site's intended purposes.</p>
<p>By using this Site and consenting to the use and disclosure of any information submitted to the Site, or information collected when the Site is accessed, the User acknowledges that there is no expectation of privacy, confidentiality or privilege in such information provided.</p>
<p>Reasonable efforts are used to secure access to any Personally Identifiable Information in our possession, but will not be liable for any failure to safeguard such information, whether inadvertent or otherwise. Personally Identifiable Information is also stored with third parties who have informed us that they also use reasonable efforts to secure access to Personally Identifiable Information, but we will not be liable for the failure of any third party to safeguard Personally Identifiable Information, whether inadvertent or otherwise.</p>

<h3 class="mb-0">Applicable Law and Jurisdiction</h3>
<br></br>

<p>The Site is maintained in the United States of America. By accessing the Site, you consent to and authorize the export of Personally Identifiable Information to the United States of America and its storage and use as specified in this Privacy Notice. This Privacy Notice shall be governed by, construed and entered in accordance with the laws of the State of California applicable to contracts deemed to be made within such state, without regard to choice of law or conflict of law provisions thereof. All disputes with respect to this Privacy Notice shall be brought and heard either in the California state courts or the federal district court of California located in Orange County, California. You consent to the in personam jurisdiction and venue of such courts. YOU HEREBY WAIVE YOUR RIGHT TO A TRIAL BY JURY WITH RESPECT TO ANY CLAIM, ACTION OR PROCEEDING, DIRECTLY OR INDIRECTLY, ARISING OUT OF, OR RELATING TO, THIS AGREEMENT TO THE FULLEST EXTENT PERMITTED BY LAW.</p>
</div>
</div>
</div>
</section>



<section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="disclaimers">
<div class="w-100">
<h2 class="mb-5">Disclaimers</h2>
<ul class="fa-ul mb-0">
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
1<sup>st</sup>Membership Obligation
Regency-Text-Field</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
2<sup>nd</sup>Membership Obligation
Regency-Text-Field</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
3<sup>rd</sup>Membership Obligation
Regency-Text-Field</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
4<sup>th</sup>Membership Obligation
Regency-Text-Field</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
5<sup>th</sup>Membership Obligation
Regency-Text-Field</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
6<sup>th</sup>Membership Obligation
Regency-Text-Field</li>
<li>
<i class="far fa-lightbulb fa-li text-warning "></i>
7<sup>th</sup>Membership Obligation
Regency-Text-Field</li>
</ul>
</div>
</section>

</div>


);
}
}

export default Gut5;
