import React, { Component } from 'react';
import Web3 from 'web3';
import './App.css';
// import ContentLoader from '../abis/ContentLoader.json'
// import ClaimManager from '../abis/ClaimManager.json'
import EvidenceManagement from '../abis/EvidenceManagement.json'
import Navbar from './Navbar'
import GutTop from './GutTop'
import GutBc from './GutBc'
import Main from './Main'
import Gut1 from './Gut1'
import Gut2 from './Gut2'
import Gut3 from './Gut3'
import Gut4 from './Gut4'
import Gut5 from './Gut5'
import GutBot from './GutBot'
import Footer from './Footer'


class App extends Component {

  async componentWillMount() {
    await this.loadWeb3()
    await this.loadBlockchainData()
  }

  async loadWeb3() {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum)
      await window.ethereum.enable()
    }
    else if (window.web3) {
      window.web3 = new Web3(window.web3.currentProvider)
    }
    else {
      window.alert('Non-Ethereum browser detected. You should consider trying MetaMask!')
    }
  }

  async loadBlockchainData() {
    const web3 = window.web3
    // Load account
    const accounts = await web3.eth.getAccounts()
    this.setState({ account: accounts[0] })
    // Network ID
    const networkId = await web3.eth.net.getId()
    const networkData = EvidenceManagement.networks[networkId]
    if(networkData) {
      const evidenceManagement = new web3.eth.Contract(EvidenceManagement.abi, networkData.address)
      this.setState({ evidenceManagement })
      const postCount = await evidenceManagement.methods.postCount().call()
      this.setState({ postCount })
      // Load Posts
      for (var i = 1; i <= postCount; i++) {
        const post = await evidenceManagement.methods.posts(i).call()
        this.setState({
          posts: [...this.state.posts, post]
        })
      }
      // Sort posts. Show highest tipped posts first
      this.setState({
        posts: this.state.posts.sort((a,b) => b.txAmount - a.txAmount )
      })
      this.setState({ loading: false})
    } else {
      window.alert('EvidenceManagement contract not deployed to detected network.')
    }
  }

  createPost(content) {
    this.setState({ loading: true })
    this.state.evidenceManagement.methods.createPost(content).send({ from: this.state.account })
    .once('receipt', (receipt) => {
      this.setState({ loading: false })
    })
  }

  txAmtPost(id, txAmount) {
    this.setState({ loading: true })
    this.state.evidenceManagement.methods.txAmtPost(id).send({ from: this.state.account, value: txAmount })
    .once('receipt', (receipt) => {
      this.setState({ loading: false })
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      account: '',
      evidenceManagement: null,
      postCount: 0,
      posts: [],
      loading: true
    }

    this.createPost = this.createPost.bind(this)
    this.txAmtPost = this.txAmtPost.bind(this)
  }

  render() {
    return (
      <div>
        <Navbar account={this.state.account} />

<div><GutTop account={this.state.account} />
</div>

        { this.state.loading
          ? <div id="loader" className="text-center mt-5 text-danger"><p><i>You need to sign the transaction before it is sent to the blockchain. After you sign and send the transaction and you see confirmations you will need to refresh your page.  <strong>The results will be visible in your browser.</strong></i></p></div>
          : <Main
          posts={this.state.posts}
          createPost={this.createPost}
          txAmtPost={this.txAmtPost}
          />
        }
          
          <div><Gut1 account={this.state.account} />
          </div>

          <div><GutBc account={this.state.account} />
            </div>

            <div><Gut2 account={this.state.account} />
            </div>

            <div><Gut3 account={this.state.account} />
            </div>

            <div><Gut4 account={this.state.account} />
            </div>

            <div><Gut5 account={this.state.account} />
            </div>

            <div><GutBot account={this.state.account} />
              </div>


<div><Footer account={this.state.account} />
</div>

      </div>
    );
  }
}

export default App;
