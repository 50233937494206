import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class Gut1 extends Component {

render() {
return (

<div class="container">

{ this.props.account
? <img
className='ml-1 rounded-5 mr-2 mb-2'
width='40'
height='40'
src={makeBlockie(this.props.account, 40).toString()} alt="AcctIconImage"/>
: <span></span>
}

{ this.props.account
? <img
className='ml-1 rounded-5 mr-2 mb-2'
width='40'
height='40'
src={`data:image/png;base64,${new Identicon(this.props.account, 40).toString()}`} alt="AcctIconImage"/>
: <span></span>
}
<h5 className="text-primary">Shareholder Account: <span id="account">{this.props.account}</span></h5>


<section class="chain-tx-sec p-3 p-lg-5 d-flex align-items-center" id="executechaintx">
<div class="row">
<div class="col-md-12 mb-5">
<div class="card h-100">
<div class="card-body">
<h2 class="card-title">Download Data from Public Nodes</h2>
<p class="card-text"><i class="fas fa-fingerprint fa-3x fa-pull-left"></i>Public node and blockchain technology platform. Regency uses node based computing and offers open source code for public viewing and verifications for stability #worldlaw-code-is-open-source which ensures transparency from inception to finalization</p>

</div>
<div class="card-footer">
<a href="#execute-asset-proxy-vote">Execute Transaction Now</a>
</div>
</div>
</div>
</div>
</section>

<section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="govplatform">
<div class="w-100">
<h2 class="mb-0">Regency Governance</h2>
<div class="subheading mb-3">A new way to govern corporate entities</div>
<p class="lead mb-3">Corporate governance is an perpetual challenge for management teams. Governance services are built in to the Regency protocols that allow owners access to services that manage the organization and ovesees the WorldLaw protocols.</p>


<li>Corporate Governance Consulting & Proactive Solutions</li>
<li>Targeted Investor Communications Campaigns</li>
<li>Vote Projections</li>
<li>Analysis of management and shareholder proposals</li>
<p class="lead mt-3 mb-3"><small>paragraph of information here. paragraph of information here. paragraph of information here</small></p>
<br></br>
<br></br>
    
<div class="resume-item d-flex flex-column flex-md-row justify-content-between">
<div class="resume-content">
<h3 class="mb-0">Transparent Management </h3>
<div class="subheading mb-3">Putting all records on the public blockchain</div>
<div>Regency provide owners and with regular customized updates that report to the business of the state of the WorldLaw protocols. These reports indicate how changes may impact their interst and promotes the best practices inc corporate governance. By identifying potential risk factors earlier in the process, the Regency protocols proactively mitigate their potential negative impact. </div>
<p class="lead mt-3 mb-3"><small>paragraph of information here. paragraph of information here. paragraph of information here</small></p>
</div>

</div>

<li>Regular updates of internal notices and corporate governance best practices.</li>
<li>Global helps owners develop strategies to manage issues that need to be addressed and responsed to by the owners.</li>
<li>Analysis of management and shareholder proposals</li>
<li>Deployment of shareholder engagement strategies</li>
<li>Regular updates of internal notices and corporate governance best practices.</li>

<p class="lead mt-3 mb-3"><small>paragraph of information here. paragraph of information here. paragraph of information here</small></p>


<br></br>
<br></br>



<div class="resume-item d-flex flex-column flex-md-row justify-content-between">
<div class="resume-content">
<h3 class="mb-0">The Regency Protocols </h3>
<div class="subheading mb-3">How Regency Governance Works</div>
<div>The Regency protocols report on the overall framework and participation of major proxy votes on each shareholder. The services broadcasts the proxy vote filings by identifying potential recommendations pursuant to a proxy advisory firm or institutional investor voting analysis. This governance approach ensures that governance practices are properly aligned and provide clear communication with shareholders to proactively manage objectives.</div>
<p class="lead mt-3 mb-3"><small>paragraph of information here. paragraph of information here. paragraph of information here</small></p>
</div>

</div>


<li>Review of applicable investor policies</li>
<li>By providing membres and shareholders regular updates regarding regulatory changes and system developments, and disclosures members and shareholders stay up-to-date. The Regency protocols provide information to glean insights into the best practices company and platform governance.</li>
<li>Evaluation of proxy participation payment guidelines, pay-on-say-model and quantitative performance model</li>
<li>Analysis of multiple plan scenarios</li>
<li>Review of applicable investor policies</li>
<li>Assessment of the impact that a negative vote recommendation may have </li>
<li>Evaluation of proxy participation payment guidelines, pay-on-say-model and quantitative performance model</li>
<li>Deployment of communication campaigns</li>


<p class="lead mt-3 mb-3"><small>paragraph of information here. paragraph of information here. paragraph of information here</small></p>

</div>
</section>


</div>

);
}
}

export default Gut1;
