import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';



class Footer extends Component {

  render() {
    return (


<footer class="footer text-center bg-light">
      <div class="container">
      <div class="row">


      <div class="col-lg-12 h-100 text-center text-lg-center my-auto">
           <ul class="list-inline mt-2 mb-2">
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Wardens</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Worldlaw</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Web3vm</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">MyDocs</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Claims</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Prepay</a>
          </li>
          <li class="list-inline-item">&sdot;</li>
          <li class="list-inline-item">
            <a href="https://github.com/worldlaw">Voteable</a>
          </li>
          </ul>
      </div>

      <div class="w-100 mt-4 mb-2">
      <div class="social-icons mt-2 mb-2">
            <a href="https://twitter.com/tenfinney">
            <i class="fab fa-twitter"></i>
            </a>
            <a href="https://github.com/worldlaw">
            <i class="fab fa-github"></i>
            </a>
            <a href="https://github.com/worldlaw">
            <i class="fas fa-user-plus"></i>
            </a>
            <a href="https://github.com/worldlaw">
            <i class="fas fa-fingerprint"></i>
            </a>
            <a href="https://github.com/worldlaw">
            <i class="fas fa-upload"></i>
            </a>
            <a href="https://github.com/worldlaw">
            <i class="fas fa-user-shield"></i>
            </a>
            <a href="https://github.com/worldlaw">
            <i class="fas fa-shield-alt"></i>
            </a>
            <a href="https://github.com/worldlaw">
            <i class="fas fa-file-download"></i>
            </a>
            <a href="https://github.com/worldlaw">
            <i class="fas fa-bullhorn"></i>
            </a>
            <a href="https://github.com/worldlaw">
            <i class="fas fa-landmark"></i>
            </a>
      </div>
      </div>
      </div>

      <div class="container">
            <p class="m-0 text-center text-dark">Copyright &copy; WorldLaw 2020 - WorldLaw.eth</p>
      </div>

<div class="d-flex justify-content-center mt-1">


      { this.props.account
  ? <img
  className='ml-1 rounded-5'
  width='24'
  height='24'
  src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
  : <span></span>
}

{ this.props.account
  ? <img
  className='ml-1 rounded-5'
  width='24'
  height='24'
  src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
  : <span></span>
}

  <h5 className="text-black text-center">Account: <span id="account">{this.props.account}</span></h5>

      </div>
      </div>

      </footer>

);
}
}

export default Footer;
